.login-form {
  background-color: #fff;
  padding: 1rem 2rem;
  width: 40%;
}
.login-form-title {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 2rem 2rem 2rem;
}
.login-layout {
  min-height: 100vh;
}

.flex-layout {
  display: flex;
  align-items: center;
  justify-content: center;
}
