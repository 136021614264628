.ant-modal .ant-modal-footer {
  margin-top: 3rem;
  display: flex !important;
}
.map-modal-button-no {
  background: #656464cc;
}
.map-modal-button-no:hover {
  background: #656464b8 !important;
}
