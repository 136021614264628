body {
  margin: 0;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.ant-pagination-item-link {
  color: #777 !important;
  height: 35px !important;
}

.ant-modal-close {
  color: rgba(0, 0, 0, 0.45) !important;
  height: 0 !important;
}

.mb1 {
  margin-bottom: 1rem;
}

.mr1 {
  margin-right: 1rem;
}

.bg-white {
  background-color: white;
}

.p1 {
  padding: 1rem
}

.pb1 {
  padding-bottom: 1rem;
}

.pb15 {
  padding-bottom: 1.5rem;
}

.pt1 {
  padding-top: 1rem;
}

.overflowy-scroll {
  overflow-y: scroll;
}