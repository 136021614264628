.niche_logo {
  display: flex;
}
.header {
  text-align: center;
  color: rgb(255, 255, 255);
  height: 64px;
  padding-inline: 10px;
  line-height: 64px;
  background-color: rgb(0, 21, 41);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-links {
  color: rgb(255, 255, 255);
}
.footer {
  color: #fff;
  height: 64px;
  background-color: #1d2939;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.auth-layout {
  min-height: 100vh;
}
.navbar-menu {
  margin-bottom: 1.5rem;
}
.auth-layout-content {
  padding: 0rem 2rem;
}

.ant-btn-primary {
  font-size: 14px !important;
  height: 40px !important;
  padding: 0px 7px;
  border-radius: 4px;
}

.head {
  font-size: 22px;
  font-weight: 600;
}

.flex-center {
  display: flex;
  align-items: center;
}

.icon-margin {
  margin-top: 1.5rem;
  margin-right: 0.25rem;
}