.vehicle-model-orgs {
    padding: 1rem;
    height: auto;
}

.orgs-select {
    width: 100%
}

.border-radius {
    border-radius: 8px;
}

.tabs-main {
    padding: 0 !important;

    .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
        border: white;
    }

    .tabs-card {
        height: 100%;
    }

    .ant-tabs-content-holder {
        background: white;
        padding: 1rem;
        border-radius: 0px 8px 8px;
    }

    .ant-tabs-nav {
        margin: 0 !important;
    }
}

.vehicle-part-tree-view {
    min-height: 52vh;
}

.org-details,
.manufacturer-details {
    margin-top: 0.5rem;

    .ant-empty-image {
        height: 90px;
    }

    .ant-descriptions-item-container {
        flex-direction: column;
    }
}

.empty-value {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 42vh;
    justify-content: center;
}

.spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 14vh;
    justify-content: center;
}

.attribute-table {
    .ant-pagination-item-link {
        display: contents !important;
    }
}